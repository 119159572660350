import { defineStore } from 'pinia';

let mapsUrl = '';
if(typeof MAPS_API_KEY !== 'undefined') {
	mapsUrl = "https://maps.googleapis.com/maps/api/js?key=" + MAPS_API_KEY + "&libraries=places";
}

export const useScriptStore = defineStore('script', {
	state: () => ({
		scripts: {
			amazonSdk: {url: 'https://img.qromo.io/img/vue/js/amazon-sdk.min.js'},
			imageCompressor: {url: 'https://img.qromo.io/img/vue/js/compressor.min.js'},
			dropzone: {url: 'https://img.qromo.io/img/vue/js/dropzone.min.js', postLoadScript: dropzonePostLoad},
			sortable: {url: 'https://img.qromo.io/img/vue/js/sortable.min.js'},
			zip: {url: 'https://img.qromo.io/img/vue/js/jszip.min.js'},
			qrs: {url: 'https://img.qromo.io/img/vue/js/easy.qrcode.min.js'},
			jsPdf: {url: 'https://img.qromo.io/img/vue/js/jspdf.umd.min.js'},
			stripe: {url: 'https://js.stripe.com/v3/'},
			maps: {url: mapsUrl, callbackOverride: 'initGoogleMapsCallback'},
			mapbox: {url: 'https://api.mapbox.com/mapbox-gl-js/v2.15.0/mapbox-gl.js', preLoadScript: loadMapboxCss},
			captcha: {url: 'https://www.google.com/recaptcha/api.js?render=6Lc_oK8ZAAAAAHHQSZ2DkU3Ja-jCDML2hqpuIBuC'},
		},
		VERSION: typeof VERSION === 'undefined' ? '1' : VERSION,
		loadedScripts: [],
		loadingScripts: {},
	}),
	actions: {
		// Load a script and ensure it is only loaded once
		loadScript(scriptName, callback) {
			if (this.loadedScripts.includes(scriptName)) {
				callback();
				return;
			}
			if (this.loadingScripts[scriptName]) {
				this.loadingScripts[scriptName].push(callback);
				return;
			}
			if(!this.scripts[scriptName]) {
				console.error(`Script ${scriptName} not found`);
				return;
			}

			if(this.scripts[scriptName].hasOwnProperty('preLoadScript')) {
				this.scripts[scriptName].preLoadScript(() =>
					this.addNewScript(scriptName, callback)
				);
			} else {
				this.addNewScript(scriptName, callback);
			}
		},
		addNewScript(scriptName, callback) {

			this.loadingScripts[scriptName] = [callback];

			let finalUrl = new URL(this.scripts[scriptName].url);
			finalUrl.searchParams.set('qv', this.VERSION);

			let callbackOverride = this.scripts[scriptName].callbackOverride || '';
			appendScriptToDOM(finalUrl.href, () => {
				this.loadedScripts.push(scriptName);
				this.loadingScripts[scriptName].forEach(callback => callback());
				this.loadingScripts[scriptName] = [];
				if(this.scripts[scriptName].postLoadScript) {
					this.scripts[scriptName].postLoadScript();
				}
			}, callbackOverride);

		},
		loadScriptsSequence(scriptNames, callback) {
			const names = scriptNames.slice();
			if (names.length === 0) {
				callback();
				return;
			}
			const scriptName = names.shift();
			this.loadScript(scriptName, () => {
				this.loadScriptsSequence(names, callback);
			});
		},
		loadScriptsParallel(scriptNames, callback) {

			let callbackCalled = false;
			for(const scriptName of scriptNames) {
				this.loadScript(scriptName, () => {

					if(this.loadedScripts.filter(script => scriptNames.includes(script)).length === scriptNames.length) {
						if(!callbackCalled) {
							callback();
							callbackCalled = true;
						}
					}

				});
			}
		}
	}
});

function appendScriptToDOM(script, callback, callbackOverride = '') {
	if(import.meta.env.VITE_MODE === 'test') {
		setTimeout(callback, 100);
		return;
	}
	const scriptElement = document.createElement('script');
	scriptElement.type = 'text/javascript';
	if(callbackOverride) {
		if(!window[callbackOverride]) {
			window[callbackOverride] = callback;
		}
		script += script.includes('?') ? '&' : '?';
		script += 'callback=' + callbackOverride;
		scriptElement.src = script;
	} else {
		scriptElement.src = script;
		scriptElement.onload = () => {
			callback();
		}
	}
	document.body.appendChild(scriptElement);
}

function dropzonePostLoad() {
	Dropzone.autoDiscover = false;
}

function loadMapboxCss(callback) {
	const link = document.createElement('link');
	link.rel = 'stylesheet';
	link.href = 'https://api.mapbox.com/mapbox-gl-js/v2.15.0/mapbox-gl.css';
	link.type = 'text/css';
	link.onload = () => {
		callback();
	}
	document.head.appendChild(link);
}